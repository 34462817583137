import React from 'react';
import { navigate } from 'gatsby';
// import Recaptcha from 'react-google-recaptcha';
import '../styles/index.scss';

// const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY;

// if (typeof RECAPTCHA_KEY === 'undefined') {
//   throw new Error(`
//   Env var SITE_RECAPTCHA_KEY is undefined! 
//   You probably forget to set it in your Netlify build environment variables. 
//   Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
//   Note this demo is specifically for Recaptcha v2
//   `)
// }

function encode(data: { [x: string]: string | number | boolean; }) {
  return (Object.keys(data))
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function Contact() {
  const [state, setState] = React.useState({});
  // const recaptchaRef = React.createRef() as React.RefObject<Recaptcha>;

  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    setState({ ...state, [e.target.name]: e.target.value })
  };

  const handleSubmit = (e: { preventDefault: () => void; target: any; }) => {
    e.preventDefault()
    const form = e.target
    // const recaptchaValue = recaptchaRef.current.getValue()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        // 'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  };

  return (
    <form
      name="contact"
      method="post"
      action="/multumim/"
      data-netlify="true"
      // data-netlify-recaptcha="true"
      onSubmit={handleSubmit}
    >
      <noscript>
        <p>E timpul să instalați un web browser modern!</p>
      </noscript>
      <fieldset>
        <p>
          <label>
            Nume și prenume:
            <br />
            <input type="text" name="nume" required onChange={handleChange} />
          </label>
        </p>
        <p>
          <label>
            Număr de telefon:
            <br />
            <input type="text" name="telefon" required onChange={handleChange} />
          </label>
        </p>
        <p>
          <select name="numar-persoane" required onChange={handleChange}>
            <option value="">Cine vine?</option>
            <option value="1">Doar eu</option>
            <option value="2">Eu și jumătatea mea</option>
          </select>
        </p>
        <p>
          <select name="numar-copii" required onChange={handleChange}>
            <option value="">Copiii vin?</option>
            <option value="0">N-am! / Nu!</option>
            <option value="1">Unul</option>
            <option value="2">Doi</option>
            <option value="3">Trei</option>
            <option value="4">Patru (cam mulți)</option>
            <option value="5">Cinci (deja exagerați)</option>
          </select>
        </p>
        <p>
          <select name="tip-meniu" required onChange={handleChange}>
            <option value="">Preferințe culinare</option>
            <option value="normal">Meniu normal (cărniță)</option>
            <option value="vegetarian">Meniu vegetarian (ceva verde)</option>
          </select>
        </p>
        <p>
          <label>
            Pentru caietul de reclamații:
            <br />
            <textarea name="mesaj" onChange={handleChange} />
          </label>
        </p>
        {/* <div className="recaptcha">
          <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
        </div> */}
        <button type="submit">Prezent!</button>
      </fieldset>
    </form>
  )
};
