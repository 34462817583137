import * as React from 'react';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import Column from '../components/column';
import Form from '../components/form';
import Map from '../components/map';
import Section from '../components/section';
import { doScrolling } from '../utils';
import '../styles/index.scss';
import HeaderImage from '../images/header-image.png';
import SectionBackgroundImage from '../images/section-background-image.jpg';
import SectionImage from '../images/section-image.png';

const handleClickToConfirma = (e: React.MouseEvent<HTMLElement>) => {
  e.preventDefault();
  doScrolling('#confirma', 1000);
};

const IndexPage = () => {
  const [state, setState] = React.useState({ activeAnchor: '' });

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams && urlParams.has('a')) {
        setState((prevState) => ({
          ...prevState,
          activeAnchor: urlParams.get('a') || ''
        }));
      }
    }
  }, []);

  const { activeAnchor } = state;

  if (activeAnchor === 'locatie') {
    doScrolling('#locatie', 1000);
  }

  return (
    <ParallaxProvider>
      <main>
        <title>Bianca &amp; Mihai - 17 Iulie 2022</title>
        <ParallaxBanner
          layers={[{ image: HeaderImage, speed: -20, opacity: [0.4, 0.4] }]}
        >
          <Section className="landing flex vertical-middle">
            <div className="wrapper text-center">
              <h1>Bianca &amp; Mihai</h1>
              <p className="date">17 iulie 2022</p>
              <p className="anchor-button">
                <a
                  href="#confirma"
                  className="button"
                  onClick={handleClickToConfirma}
                >
                  Confirmă
                </a>
              </p>
            </div>
          </Section>
        </ParallaxBanner>
        <ParallaxBanner
          layers={[
            { image: SectionBackgroundImage, speed: -20 },
            { image: SectionImage, speed: -30, opacity: [0.6, 0.6] },
          ]}
        >
          <Section className="bg-green" id="locatie">
            <div className="grid wrapper text-center">
              <Column opacity={[0.95, 1]} className="column" id="column-1">
                <h3>Oare câți vor citi numele bisericii până la capăt?</h3>
                <p>12:30, 17 iulie 2022</p>
                <p>Parohia Ortodoxă "Intrarea in Biserică a Maicii Domnului"</p>
                <p className="location-address">Pța Unirii, nr. 9, Bistrița</p>
                <Map source="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJe5zthKv3SUcRPcF6EaSu4q8&key=AIzaSyBakP7VJ81EEvQnyOQ7eyu9HHqlocsbAtM" />
              </Column>
              <Column opacity={[0.95, 1]} className="column" id="column-2">
                <h3>
                  Party... pentru o mână de oameni, restul vor fi afară la
                  țigară.
                </h3>
                <p>14:00, 17 iulie 2022</p>
                <p>Pico Verde</p>
                <p className="location-address">
                  Str. Drumul Dumitrei Nou, nr. 22-24, Bistrița
                </p>
                <Map source="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJEQuWven3SUcR0CdjFp8oEPo&key=AIzaSyBakP7VJ81EEvQnyOQ7eyu9HHqlocsbAtM" />
              </Column>
            </div>
          </Section>
        </ParallaxBanner>
        <Section className="confirma" id="confirma">
          <div className="wrapper text-center">
            <h3>Test grilă</h3>
            <Form />
          </div>
        </Section>
      </main>
    </ParallaxProvider>
  );
};

export default IndexPage;
