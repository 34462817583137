import * as React from 'react';
import useCurrentDimensions, { getWidth } from '../hooks/useCurrentDimensions';
import '../styles/index.scss';

const Map: React.FC<{
  source?: string;
}> = ({ source = '' }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [state, setState] = React.useState({ width: 600, height: 450 });

  React.useEffect(() => {
    if (ref.current) {
      setState((prevState) => ({
        ...prevState,
        width: getWidth(ref),
        height: getWidth(ref) * 0.75,
      }));
    }
  }, []);

  const { width, height } = state;

  return (
    <div className="map-wrapper" ref={ref}>
      <iframe
        width={useCurrentDimensions(ref).width | width}
        height={useCurrentDimensions(ref).height | height}
        style={{ border: 0 }}
        loading="lazy"
        allowFullScreen
        src={source}
      ></iframe>
    </div>
  );
};

export default Map;
