import * as React from 'react';
import { useParallax } from 'react-scroll-parallax';
import '../styles/index.scss';

const Column: React.FC<{
  className?: string;
  id?: string;
  opacity?: [number, number];
}> = ({ className = '', id = '', opacity, children }) => {  
  let endScroll;

  if (typeof window === 'undefined' || !window.document) {
    endScroll = 0;
  } else {
    endScroll = document.getElementById("confirma")?.offsetHeight;
  }

  const { ref } = useParallax({
    opacity,
    endScroll,
    startScroll: 0
  });

  return (
    <div ref={ref as React.RefObject<HTMLDivElement>} className={className} id={id}>
      {children}
    </div>
  );
};

export default Column;
