const getElementY = (query: string): number => {
  if (typeof window === 'undefined' || !window.document) {
    return 0;
  }

  const elementTop =
    document.querySelector(query)?.getBoundingClientRect()?.top ?? 0;
  return window.pageYOffset + elementTop;
};

export const doScrolling = (
  elementSelector: string,
  duration: number
): void => {
  const startingY = window.pageYOffset;
  const elementY = getElementY(elementSelector);
  const diff = elementY - startingY;
  let start: number;

  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    const time = timestamp - start;
    const percent = Math.min(time / duration, 1);

    window.scrollTo(0, startingY + diff * percent);

    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
};
