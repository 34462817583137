import * as React from 'react';
import { useParallax } from 'react-scroll-parallax';
import '../styles/index.scss';

const Section: React.FC<{
  className?: string;
  id?: string;
  speed?: number;
}> = ({ className = '', id = '', speed, children }) => {
  const { ref } = useParallax({
    speed
  });

  return (
    <section ref={ref} className={className} id={id}>
      {children}
    </section>
  );
};

export default Section;
