import { useState, useEffect } from 'react';

export const getWidth = (
  mapWrapperHTMLElement: React.RefObject<HTMLDivElement>
): number => mapWrapperHTMLElement?.current?.clientWidth ?? 0;

const useCurrentDimensions = (
  mapWrapperHTMLElement: React.RefObject<HTMLDivElement>
): { width: number; height: number } => {
  const mapWrapperWidth = getWidth(mapWrapperHTMLElement);
  let [width = mapWrapperWidth ?? 0, setWidth] = useState(
    getWidth(mapWrapperHTMLElement)
  );

  useEffect(() => {
    let timeoutId: null | ReturnType<typeof setTimeout> = null;
    const resizeListener = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(
        () => setWidth(getWidth(mapWrapperHTMLElement)),
        150
      );
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return {
    width,
    height: width * 0.75,
  };
};

export default useCurrentDimensions;
